:root{
    --primary-color: hsl(220, 65%, 15%);
    --secondary-color: hsl(203, 40%, 33%);
    --idle-color: #424242;
    --busy-color: #66bb6a ;
    --offline-color: #EF5350;
    --total-color: rgb(255, 191, 0);
}

body, html {
    font-family: 'Open Sans', sans-serif;
    /*font-family: 'Roboto', sans-serif*/
    height: 100% !important; /* Forces main div to take up the entire height of the viewport */
    -moz-osx-font-smoothing: grayscale !important;
    -webkit-font-smoothing: antialiased !important;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    /*font-family: 'Roboto', sans-serif;*/
}


.lead {
    font-size: 1.15rem;
    color: black;
}

.primary-color {
    background-color: var(--primary-color);
}


.base-color {
    background-color: var(--secondary-color);
}



/* Basic white wrapper for content w/ a shadow 
    Use the following structure:
    <div className="card-wrapper">
        <div className="card-label">
            (text in label)
        </div>
        <div className="card card-with-label">
            <div className="card-body">
          
            </div>
        </div>
    </div>
*/

.card{
    overflow: auto;
}


.card-wrapper {
    /*margin-top: 20px;*/
    height: 100%;
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
}

.site-card {
    overflow: hidden;
    height: calc(100vh - 108px);
}

.site-card-in-scheduler {
    overflow: hidden;
    height: calc(100vh - 540px);
}

#sitesWrapper {
    margin-bottom: 15px;
}

.tab-height {
    height: 100%;
}

.tab-height-in-scheduler {
    height: 94%;
}

.site-list-scroll-height {
    height: 94%;
}

/* Label for cards attached to the tap (overlapping) */
.card-label {
    display: inline-block;
    background: linear-gradient(to bottom, var(--primary-color), hsl(220, 65%, 20%));
    color: white;
    font-weight: 400;
    border: none;
    outline: none;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
    /*box-shadow: 0px 1px 2px 0 hsl(0, 0%, 70%);*/
    border-radius: 0.250rem;
    font-size: 1.0rem;
    margin-left: 25px;
    margin-right: auto;
    text-align: center;
    line-height: 2.5rem;
    justify-content: center;
    border-radius: 6px 7px 7px 6px;
}

.card {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    border-radius: 0.25rem;
    padding: 20px;
    margin: 10px 10px 10px 10px;
    border: none;
    border-width: 0;
    /*box-shadow: none;*/
}

/* Use for cards you want to use with a card-label */
.card-with-label {
    margin-top: -25px;
    padding-top: 40px;
}

.card-body {
    padding: 0px;
}

/*.fit-screen-height {
    max-height: calc(100vh - 80px);
}*/

.make-scrollable {
    overflow-y: scroll;
    height: inherit;
}

/* Allows child elements to be scrollable while certain elements are fixed */
.scroll-parts {
    display: flex;
    flex-direction: column;
}

@media (min-width: 1200px) {
    .scroll-parts {
        /*height: calc(100vh - 98px);}*/
        height: 100%;
    }
}

/* Makes a child element scrollable 
    Parent must have class: scroll-parts
*/
/* .make-scrollable {
        height: 100%;
        overflow-y: auto;
    }
       */

.half-screen-height {
    height: 40vh;
}


.table-button{
    white-space: nowrap;
    overflow: hidden;
}

.datepicker-label{
    min-width: 70px;
}

@media only screen and (max-width: 768px) {
    .table-button {
      width: 100%;
      display: block;
    }
    .table-button-wrapper{
        width:100%;
        display: block;
        margin-right: 2px;
    }
    .dropdown-toggle::after{
        display: none;
    }
  }

.table-wrapper {
    padding: 5px 5px 5px 5px;
}

.table thead th {
    border-bottom: 2px solid rgb(112, 112, 112);
}

.table-bordered th{
    border: none;
}

.table-bordered{
    border: none;
}

/* Adds a border around an item you want to debug, eg. a div that is not acting properly */
.debug-mode {
    border: 1px solid blue;
    border-radius: .375em;
}

/* The following styles will override the default bootstrap css to 
    apply custom styling :) 
*/

.btn {
    display: flex;
    justify-content: space-around;
    /*border: none !important;*/
    border-color: #ced4da;
    outline: none !important;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    /*box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);*/
    transition: all .22s;
    border-radius: 0;
}

    .btn:hover {
        transform: translateY(-2px);
    }

    .btn:focus, .btn.focus {
        box-shadow: 0 7px 14px rgba(50,50,93,.11), 0 3px 6px rgba(0,0,0,.08) !important;
    }


.btn-primary {
    background-color: #0D1E3F !important;
    /* background-color: hsl(45, 100%, 50%) !important; */
    /*background: linear-gradient(to bottom, hsl(50, 100%, 52%) 0%, hsl(45, 100%, 52%) 100%) !important;*/
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08) !important;
    outline: none !important;
    border: none !important;
}

    .btn-primary:hover, .btn-primary.hover {
        filter: brightness(110%);
        box-shadow: 0 7px 14px rgba(50,50,93,.11), 0 3px 6px rgba(0,0,0,.08) !important;
    }

.btn-secondary {
    background-color: #00C6C0 !important;
    /* background-color: hsl(187, 26%, 55.5%) !important; */
    /*background: linear-gradient(to bottom, hsl(50, 100%, 52%) 0%, hsl(45, 100%, 52%) 100%) !important;*/
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08) !important;
    outline: none !important;
    border: none !important;
}

    .btn-secondary:hover, .btn-secondary.hover {
        filter: brightness(110%);
        box-shadow: 0 7px 14px rgba(50,50,93,.11), 0 3px 6px rgba(0,0,0,.08) !important;
    }


.btn-light {
    background-color: hsl(0, 0%, 100%) !important;
    color: hsl(0, 0%, 50%) !important;
    /*background: linear-gradient(to bottom, hsl(50, 100%, 52%) 0%, hsl(45, 100%, 52%) 100%) !important;*/
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
}

    .btn-light:hover, .btn-light.hover,
    .btn-light:focus, .btn-light.focus,
    .btn-light:active, .btn-light.active {
        box-shadow: 0 7px 14px rgba(50,50,93,.11), 0 3px 6px rgba(0,0,0,.08) !important;
    }

.btn-basic {
    background-color: inherit;
    color: hsl(0, 0%, 50%) !important;
    /*background: linear-gradient(to bottom, hsl(50, 100%, 52%) 0%, hsl(45, 100%, 52%) 100%) !important;*/
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    width: auto;
}


    .btn-basic:hover, .btn-basic.hover {
        filter: brightness(140%);
        box-shadow: none !important;
        transform: none !important;
    }


.btn-outline {
    background-color: hsl(0, 0%, 100%) !important;
    color: hsl(187, 26%, 55.5%) !important;
    box-shadow: none;
    outline: none !important;
    border: 1.5px solid var(--primary-color) !important;
}

    .btn-outline:hover, .btn-outline.hover,
    .btn-outline:focus, .btn-outline.focus,
    .btn-outline:active, .btn-outline.active {
        box-shadow: 0 7px 14px rgba(50,50,93,.11), 0 3px 6px rgba(0,0,0,.08) !important;
    }

.btn-outline-secondary {
    color: hsl(187, 26%, 55.5%) !important;
    /*border-color: hsl(187, 26%, 55.5%) !important;*/
    background-color: white !important;
}

.btn-outline-primary:hover {
    color: white !important;
    background-color: hsl(187, 26%, 55.5%) !important;
    border-color: hsl(187, 26%, 55.5%) !important;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: hsl(187, 26%, 55.5%) !important;
}

input.disabled{
    background-color: rgb(82, 99, 102) !important;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    background-color: hsl(187, 26%, 55.5%) !important;
    border-color: hsl(187, 26%, 55.5%) !important;
}


/* Dropdown -items*/
.buttons-columnVisibility.active {
    background-color: white;
    color: black;
}

    .buttons-columnVisibility.active::before {
        position: absolute;
        left: .4rem;
        content: '✓';
        font-weight: 600;
    }

.buttons-columnVisibility:not(.active) {
    background-color: white;
    color: gray;
}

.dropdown-item:hover, .dropdown-item:active {
    cursor: pointer;
    background-color: hsl(0, 0%, 97%);
    color: black;
}

.dropdown-item:focus {
    outline: none !important;
}

.btn-icon {
    margin-right: 6px !important;
}

.material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
}

    .material-icons.md-dark.md-inactive {
        color: rgba(0, 0, 0, 0.26);
    }

.material-icons.md-light {
    color: rgba(255, 255, 255, 1);
}

    .material-icons.md-light.md-inactive {
        color: rgba(255, 255, 255, 0.3);
    }

.material-icons.delete-red {
}

.dropdown-menu {
    box-shadow: 0 3px 6px rgba(0,0,0,.08) !important;
}

.custom-radio {
    margin-bottom: 10px !important;
}

/* Customize checkbox styling */
.checkbox label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.3em;
    height: 1.3em;
    float: left;
    margin-right: .5em;
}

    .checkbox .cr .cr-icon {
        position: absolute;
        font-size: .8em;
        line-height: 0;
        top: 50%;
        left: 15%;
    }

.checkbox label input[type="checkbox"] {
    display: none;
}

    .checkbox label input[type="checkbox"] + .cr > .cr-icon {
        opacity: 0;
    }

    .checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
        opacity: 1;
    }

    .checkbox label input[type="checkbox"]:disabled + .cr {
        opacity: .5;
    }


/* Tables */

.btn-table {
    margin-right: 10px;
    border-radius: .25rem !important;
}

/* Forms validation */

.invalid-feedback {
    font-size: 90%;
}

#add-new-group-btn {
    min-height: 35px;
}


/* Removes blue outline when clicking items in the dropdown menu*/
span:focus {
    outline: none !important;
}


/* prevents modal-open from messing with the padding-right */
.modal-open {
    padding-right: 0px !important;
    overflow: inherit;
}

/* Make table stripes lighter */

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
}

.table-striped.table-hover > tbody > tr:not(.selected):hover > td,
.table-striped.table-hover > tbody > tr:not(.selected):hover > th {
    background-color: hsl(203, 40%, 90%);
}

/* Fix scroll body being a higher z-index from scroller
.dataTables_scrollBody {
    z-index: 0;
} */


/* Don't show underline on show/hide password icon */
.input-group-append a, .input-group-append a:hover {
    color: #333;
    text-decoration: none;
}



/* add affects to nav menu icon */
.fa-bars {
    display: block;
    width: 24px;
}

    .fa-bars:before,
    .fa-bars:after {
        background-color: #fff;
        content: '';
        display: block;
        height: 3px;
        transition: all 200ms ease-in-out;
    }

    .fa-bars:before {
        box-shadow: 0 10px 0 #fff;
        margin-bottom: 16px;
    }

    .fa-bars:hover:before {
        box-shadow: 0 0 0 #fff;
        transform: translateY(10px) rotate(45deg);
    }

    .fa-bars:hover:after {
        transform: translateY(-10px) rotate(-45deg);
    }

    /* styling obtained from jquery contextmenu */
.context-menu{
    border: 1px solid #bebebe;
    border-radius: .2em;
    max-width: 26em;
    min-width: 13em;
    padding: .25em 0;
    box-shadow: 0 2px 5px rgba(0,0,0,.5) !important;
    display: block;
    background-color: white;
}

.react-contextmenu{
    z-index: 100;
}

.context-menu-item{
    padding: .2em 2em .2em .4em;
    font-weight: 400;
    font-size: 1.1em;
}



.context-menu-item > i {
    font-weight: 400;
    font-size: 1em;
    padding-right: 1em;
    color: #2980b9;
}


.context-menu-item:hover{
    cursor: pointer;
    background-color: #2980b9;
    color: #ffffff
}

.context-menu-item:hover > i{
    color: #ffffff;
}

.context-menu-separator{
    border-bottom: 1px solid #c0c0c0;
    padding: 0;
    margin: 0.35em;
}

.add-user-output-box{
    background-color: white;
    border: solid 1px darkgray;
    height: 15vh;
    overflow-y: auto;
}

.output-button{
    width: 100%;
}

.add-user-input-box{
    background-color: white;
    border: solid 1px darkgray;
    height: 32vh;
    overflow-y: auto;    
}

.add-user-input-box input{
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 1px darkgray;
}

.add-site-selected{
    background-color: rgba(19, 94, 192, 0.425)
}

.add-site-added{
    color: rgb(155, 155, 155);
}
.add-site-inherited{
    color: rgb(39, 39, 110);
    background-color: rgb(196, 196, 196);
}

.status-cards-wrapper{
    padding: 0 20px;

}

.status-card{
    width: 100%;
    min-width: 200px;
    cursor: pointer;
    width: auto;
    color: white;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status-card.green{
    color: white;
    background-color: rgb(1, 78, 1);
}

.status-card.red{
    color: white;
    background-color: rgb(139, 0, 0);
}

.status-card-count{
    font-size: 32px;
}

.status-card-name{
    margin-left: 5px;
    font-size: 14px;
}

.dropdown {
    padding-top: 3px;
}
/* 
th td thead{
    white-space: nowrap;
    overflow: hidden;
    text-overflow:  ellipsis;
    max-width: 20px;
} */

/* td{
    max-width: 20px;
} */

.dt-nowrap{
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 120px;
}

.dt-nowrap-nohide{
    max-width: none;
}

.no-text-select{
    cursor: pointer;
    user-select: none;
}

.rx-monitor td{
    background-color: rgb(209, 209, 209);
}

.rx-feature-disabled td{
    color: gray;
}

.unowned-site td{
    background-color : rgb(209, 209, 209);
}

.switch-disabled.checked > span{
    background-color: rgb(112, 139, 179) !important;
}

.switch-disabled > span{
    background-color: rgb(136, 136, 136) !important;
}

/* .switch-disabled .checked > span{
    background-color
} */

@supports (-ms-ime-align: auto) {
    .react-datepicker{
        min-width: 343px;
    }
}

tbody .selected {
    background-color: hsl(203, 40%, 70%) !important;
}

tr.last-selected{
    background-color: #769ec4 !important;
}

#tooltip{
    position: fixed;
    z-index: 1001;
    display: none;
    font-size: 12px;
    border: 1px solid gray;
    /* border-radius: 5px; */
    padding: 2px;
    background-color: #fff;
    overflow: hidden;
    pointer-events: none;
}


.react-datepicker__time-container{
    min-width: 100px;
}

.react-datepicker-popper{
    z-index: 11001 !important;
}
