#scheduler-log-page>.col-md-12 {
    margin: 30px 30px;
    max-width: 96%;

}

.scheduler-log-btns {
    margin-bottom: 20px;
    top: 0;
    right: 0;
    padding-left: 20px;
}

/* Fixed issue that the datepicker is under the table rows */
.scheduler-log-btns .react-datepicker-popper {
    z-index: 3;
}

.scheduler-log-btns .react-datepicker-wrapper {
    width: 34%;
}

.scheduler-log-datepicker {
    margin-top: 7px;
}

#scheduler-log-card .card-wrapper {
    margin-top: -10px;
}
