.avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    width: 35%;
    height: 0%;
  }

  .welcome {
      margin-top: 10px;
      text-align: center;
      color: white;
  }

  .currentUser {
      background-color: #00C6C0;
      width: 50%;    
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
  }

  .user-label {
      padding-top: 4px;
      padding-bottom: 4px;
      text-align: center;
      color: white;
  }