 /* Total Receivers box*/
.totalRx {
    background-color: var(--total-color);
    /* border-radius: 0.15rem; */
}
.totalRx-card {
    background-color: #7fbbdd;
    color: #fff;
    display: inline-block;
    color: white;
    font-weight: 400;
    border: none;
    outline: none;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
    font-size: 1.0rem;
    margin-left: 25px;
    margin-right: auto;
    text-align: center;
    line-height: 2.5rem;
    justify-content: center;
}
.totalRx p.card-text.h2.text-center {
    color: white;
}

/* IdleRx Receivers box*/
.IdleRx-card {
    background-color: #f9d9a0;
    color: #1e1910;
    display: inline-block;
    font-weight: 400;
    border: none;
    outline: none;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
    font-size: 1.0rem;
    margin-left: 25px;
    margin-right: auto;
    text-align: center;
    line-height: 2.5rem;
    justify-content: center;
}

.IdleRx {
    background-color: var(--idle-color);
    /* border-radius: 0.15rem; */
}

.IdleRx p.card-text.h2.text-center {
    color: white;
}


/* Busy Receivers box*/
.BusyRx-card {
    background-color: #bfd8d0;
    color: #1e1910;
    display: inline-block;
    font-weight: 400;
    border: none;
    outline: none;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
    font-size: 1.0rem;
    margin-left: 25px;
    margin-right: auto;
    text-align: center;
    line-height: 2.5rem;
    justify-content: center;
}

.BusyRx {
    background-color: var(--busy-color);
    /* border-radius: 0.15rem; */
}

.BusyRx p.card-text.h2.text-center {
        color: white;
}

/* offline Receivers box*/
.offilineRx-card {
    background-color: #e5b7b7;
    color: #1e1910;
    display: inline-block;
    font-weight: 400;
    border: none;
    outline: none;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
    font-size: 1.0rem;
    margin-left: 25px;
    margin-right: auto;
    text-align: center;
    line-height: 2.5rem;
    justify-content: center;
}

.offlineRx {
    background-color: var(--offline-color);
    /* border-radius: 0.15rem; */
}

.offlineRx p.card-text.h2.text-center{
    color:white;
}
 /* text color of the table  */
.card-text {
    color: #606060;
}

/* text color of the About page  */
.text-color {
    color: #d6d6d6;
}

/* remove the horizontal line of the header */
.table th {
    border-top: none;
}


.btn-group{
    margin-bottom:10px;
    margin-top:10px;
  
}

/* alert table check Box*/
table.dataTable tbody td.select-checkbox:before, table.dataTable tbody th.select-checkbox:before {
    content: ' ';
    margin-top: -6px;
    margin-left: -6px;
    border: 1px solid #00ceef;
}

/* #remove-table .dataTables_scrollBody {
    visibility: hidden;
} */

.card-with-label {
    margin-top: 20px !important;
    padding-top: 40px !important;
}

.card {
    border-radius: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.custom-materialize-icon {
    color: white;
    font-size: 3rem !important;
    padding-top: 25px !important;
}

.card-body-text {
    color: white;
}

.card-text {
    color: white !important;
}