
#loginPage{
    position: absolute;
    width: 100%;
    height: 100%
}

/* #title {
    font-size: 22px;
} */

#login-options {
    margin: 0;
}

.checkbox {
    margin-top: 3px !important;
}


.form-group-no-padding {
    margin-bottom: 0;
    margin-top: 15px;
}



/* the title CMS in the middle */
#loginPage .title{
    text-align:center;
    font-size: 27px;
    color: #0D1E3F !important;
}

#central-mgt {
    margin-top: 20px;    
}

/* fixed the size and the margin of the error text*/
#loginPage .text-danger {
    font-size: 12px;
    margin-top: 5px;
}

/* password and icon box */
@media (min-width: 320px) and (max-width: 480px) {
    .input-group > .form-control:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 80%;
    }

}


.field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    margin-right: 28px;
    position: relative;
    z-index: 2;
}

.login-input-field {
    margin-right: auto;
    margin-left: auto;
}

#login-username {
    margin-top: 50px;
}

#log-in {
    margin-top: 30px;
    font-size: 14px;
    padding: 10px 14px 10px;
    margin-bottom: 35px;
}

#remember-me-wrapper {
    text-align: center;
}


