body {
    font-size: .875rem;
    /*background: #f1f1f1;*/
    background: #fdfdfd;
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

.menu-item-icon {
    /*width: 20px;
    height: 20px;*/
    font-size: 1.3rem;
    margin-right: 13px;
}

.nav-color-picker{
    background-color: transparent;
    border: none;
}
/*
 * Sidebar
 */

.sidebar {
    position: sticky;
    float: left; 
    top: 50px; /*top offset should be equal to the height of the top navbar*/
    background-color: var(--primary-color);
    width: 250px;
    transition: all ease-in-out 0.3s;
    height: calc(100vh - 50px);
}

    .sidebar.inactive {
        margin-left: -250px;
    }

@media (max-width: 768px) {
    .sidebar {
        margin-left: -250px;
    }

        .sidebar.inactive {
            margin-left: 0;
        }
}


@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.sidebar .nav-link {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    /* color: hsl(0, 0%, 80%);*/
    color: hsl(0, 0%, 60%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

    /*.sidebar .nav-link .menu-item-icon {
        color: hsl(0, 0%, 85%);
    }*/
    

    .sidebar .nav-link.active,
    .sidebar .nav-link:hover {
        color: hsl(0, 0%, 95%);
    }

   
        .sidebar .nav-link.active .menu-item-icon,
        .sidebar .nav-link:hover .menu-item-icon {
            color: hsl(0, 0%, 100%);
        }

.sidebar-heading {
    font-size: .85rem;
    text-transform: uppercase;
}

/*
 * Content
    Put main stuff in here 
 */

[role="main"] {
    padding-top: 5px; /* Space for fixed navbar */
}

/*
 * Navbar
 */

.navbar {
    background-color: var(--primary-color);
    font-family: 'Roboto', sans-serif;
    height: 50px;
    padding: 5px;
}

.company-logo {
    height: 30px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
}

.navbar-brand {
    min-width: 250px;
    max-width: 250px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: auto;
}

.navbar-expand {
    justify-content: space-between;
}

.navbar-nav .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
}
/*
 * Utilities
 */

.border-top {
    border-top: 1px solid hsl(0, 0%, 10%);
}

.border-bottom {
    border-bottom: 1px solid hsl(0, 0%, 10%);
}

/*div {
    border: 2px solid blue;
}*/

.horizontal-flow {
    display: flex;
    flex-direction: row;
}

h3 {
    font-weight: 300;
    font-size: 1.7rem;
}

p {
    color: hsl(0, 0%, 20%);
}

h4 {
    font-weight: 400;
    font-size: 1.3rem;
}


/* Disable un-implimented sidebar items */
.disabled {
    pointer-events: none;
    opacity: 0.75;
}

.no-click {
    pointer-events: none;
}

.hidden {
    display: none;
}

.fas {
    font-size: 1.2rem;
}

.sidebarCollapse {
    display: flex;
    margin: 10px;
    margin-right: 5px;
    padding: 10px;
    align-items: center;
}

/* Make the content take up the rest of the screen (remaining after sidebar + navbars) */
#destination {
    flex-grow: 1;
    overflow: auto;
}

.blue {
    background-color: blue;
    height: 100%;
}

.sidebar .nav-link.selected,
.sidebar a .nav-link.selected {
    /*color: hsl(0, 0%, 95%);*/
    color: hsl(0, 0%, 100%);
}

.sidebar .nav-link .menu-item-icon:hover {
    color: hsl(0, 0%, 100%);
}

/* Menu on Navbar */
.menu {
    font-size: 2.0rem;
}

