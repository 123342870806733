.column-shown {
    color: hsl(0, 0%, 0%);
}

.column-hidden {
    color: hsl(0, 0%, 60%)
}

/*.dropdown-item:hover {
    color: hsl(0, 0%, 0%);
}*/

.group-checkbox-label {
    cursor: pointer;
}

    .group-checkbox-label:hover {
        background-color: rgba(52, 152, 219, 0.6) !important;
    }

.ui-selected {
    background-color: rgba(52, 152, 219, 0.6) !important;
}

.highlight-right-click {
    background-color: rgba(52, 152, 219, 0.3) !important;
}

/* Table Header */
th {
    font-size: .75rem;
}

/* Table Items */
td {
    font-size: .82rem;
}

.table td, .table th {
    padding: .5rem;
}

.green {
    color: rgb(1, 173, 9);
}

.red {
    color: red;
}

.table--info--light {
    color: hsl(0, 0%, 40%);
}

@media (min-width: 1200px) {
    .min-width-300-xl {
        min-width: 300px;
    }

    .max-width-xl {
        max-width: calc(100% - 310px);
    }
}

div.DTS tbody th, div.DTS tbody td {
    white-space: normal;
}

/* .dataTables_scrollBody {
   height:200px; 
   overflow:auto;
   position:inherit;
} */


/* Responsive Actions button in the Schedule page */
.table-responsive {
    overflow-x: inherit;
}


.dropdown-menu {
    border-style: none;
    background-color: moz-linear-gradient(left, rgba(227,227,227,1) 0%, rgba(252,252,252,0.98) 100%);
    border-radius: .5rem;
}


.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 110px;
}

/* Responsive show/Hide columns, select Policy, and Export at Reciever table */
@media screen and (max-width: 768px) {
    .dt-buttons {
        display: inline-block;
    }

    .btn-group > .btn {
        width: 100%;
        display: inline-block;
        margin-bottom: 10px;
        flex: 0 1 auto;
        position: relative;
    }

  
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: inherit;
        max-width: 100%;
    }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: inherit;
        max-width: 100%;
    }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: inherit;
        max-width: 100%;
    }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: inherit;
        max-width: 100%;
    }
}



/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: inherit;
        max-width: 100%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {

    #receivers-table_wrapper .col-md-6:first-child {
        flex: inherit;
        max-width: 100%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

/* @media (min-width: 768px) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: 100%;
        max-width: 80%;
    }

    #reciever-table_filter .dataTables_wrapper .dataTables_filter {
        margin-top: 15px;
    }
} */

/* @media (min-width: 1025px) and (max-width: 1999px) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: 10%;
        max-width: 100%;
    }


    div.dataTables_wrapper div.dataTables_filter {
        text-align: right;
        margin-top: 13px;
    }
} */
#actionDetail .justify-content-end {
    margin-right: 10px;
}


.rc-time-picker-panel{
    z-index: 11000;
}

/* scrollar for create new task */
.modal-body-schedule > .modal-body {
    overflow: scroll;
    overflow-x: hidden;
}

/* toggle switch at task table */
.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 19px;
}

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

    .slider:before {
        position: absolute;
        content: "";
        height: 11px;
        width: 11px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 19px;
}

    .slider.round:before {
        border-radius: 50%;
    }

/* execution time, start date, end date input box */
.form-control1 {
    display: inline-block;
    width: 80%;
    height: calc(2.25rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: .15rem;
    -webkit-appearance: none;
}
/* name task */
.form-control-1, select#actions {
    display: inline-block;
    width: 100%;
    /*height: calc(2.25rem + 2px);*/
    /*padding: .375rem 1.75rem .375rem .75rem;*/
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #A9B8C7;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: .15rem;
    -webkit-appearance: none;
}




/* week days*/
#weekdays {
    margin-left: 20px;
}

/* @media (min-width: 1025px) and (max-width: 1280px) {
    #tasks-table_wrapper .col-md-6:last-child {
        max-width: 51%;
        display: inline-block;
        flex: 0 0 100%;
    }

    .dataTables_wrapper div.dataTables_filter {
        text-align: inherit;
        margin-top: 10px;
    }
} */

@media (max-width: 1436px) and (min-width: 768px) {
    #tasks-table_wrapper .col-md-6:last-child {
        flex: 0 0 51%;
        max-width: 100%;
    }
}
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    #tasks-table_wrapper .col-md-6:last-child {
        flex: 0 0 50%;
        max-width: 100%;
    }
}


@media (min-width: 1025px) and (max-width: 1280px) {
    #tasks-table_wrapper .col-md-6:last-child {
        flex: 0 0 50%;
        max-width: 100%;
    }
}

/* @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    #tasks-table_wrapper .col-md-6:last-child {
        display: inherit;
        flex: inherit;
    }

    .dataTables_wrapper div.dataTables_filter {
        text-align: inherit;
        margin-top: 15px;
    }
} */

@media (min-width: 481px) and (max-width: 767px) {
    #tasks-table_wrapper .col-md-6:last-child {
        max-width: 67%;
        display: table;
    }
}

@media (min-width: 1281px) {
    #tasks-table_wrapper .col-md-6:last-child {
        max-width: 50%;
        flex: 0 0 100%;
    }
}

@media (min-width: 1200px) and (max-width: 1280px) {
    #tasks-table_wrapper .col-md-6:last-child {
        max-width: 50%;
        flex: 0 0 100%;
    }
}

@media (min-width: 1025px) and (max-width: 1999px) {
    #tasks-table_wrapper .col-md-6:last-child {
        max-width: 50%;
        flex: 0 0 100%;
    }
}


@media (min-width: 768px) {
    #tasks-table_wrapper .col-md-6:first-child {
        flex: 100%;
        max-width: 85%;
    }
}

@media (min-width: 1025px) and (max-width: 1999px) {
    #tasks-table_wrapper .col-md-6:first-child {
        flex: 10%;
        max-width: 100%;
    }
}

.text-white {
    color: white;
}






/*Create New Task*/
.nav-tabs {
    border-bottom: none;
}

.modal-body li.nav-item a {
    box-shadow: inset 0 -2px white;
    color: white;
}

.nav-tabs .nav-link.active {
    color: #90ceec;
    background-color: transparent;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    border-color: inherit;
}

.modal-body li.nav-item a.active {
    box-shadow: 0 -2px #90ceec;
}

.modal-footer {
    border-top: 1px solid #e9ecef;
}


label.ccol-form-label.actions-text, label.ccol-form-label.task-text, label.ccol-form-label.desc-text {
    /*margin-top: 15px;*/
    margin-left: 20px;
}

/*.form-control-1, select#actions {
    margin-left: 30px;
    margin-top: 5px;
}*/


button.btn.btn-light {
    /*margin-left: 30;*/
    /*margin-left: 30px;*/
    margin-top: 5px;
}
