
.light-mode {
    --modal-label-color: #000000;
    --modal-title-color: #ffffff;
    --modal-text-color: #000000;
    --modal-background-color: #ffffff;
    --modal-overlay-background-color: #2125299c;
    --modal-select-color: #000000;
    --modal-file-input-color: #000000;
    --modal-error-color: red;
    --modal-valid-color: green;
    --modal-info-color: #0D1E3F;
    --modal-section-color: #00B0FF;
    --modal-subsection-color: #000000;
    --modal-header-color: #0D1E3F;
    --modal-close-btn-color: #ffffff;
    --modal-tab-active-color: #00B0FF;
    /* --modal-tab-active-color: #00C6C0;  */
    --modal-tab-inactive-color: #A7AABC;
    --modal-disabled-color: #7e7e7e;
    --modal-tabHeader-font-size: 13px;
    --modal-button-color: #00C6C0;
}

.dark-mode {
    --modal-label-color: #ffffff;
    --modal-title-color: #ffffff;
    --modal-text-color: #ffffff;
    --modal-background-color: rgb(72,84,97);
    --modal-header-color: rgb(72,84,97);
    /* --modal-background-image:  linear-gradient(315deg, #485461 0%, #28313b 74%); */
    --modal-overlay-background-color: #2125299c;
    --modal-select-color: #aabed0;
    --modal-file-input-color: #ffffff;
    --modal-error-color: #E57373;
    --modal-valid-color: #66BB6A;
    --modal-info-color: #29B6F6;
    --modal-section-color: #29B6F6;
    --modal-subsection-color: #ffffff;
    --modal-close-btn-color: #ffffff;
}
/* .Modal {
    position: absolute;
    left: 30%;
    right: 30%;
    top: 30%;
    max-width: 800px;
    min-width: 400px; 
    height: auto;
    overflow: auto;
    background-color: var(--modal-background-color);
   
  } */

.form-control:disabled {
    background-color: transparent;
}

.Overlay {
    z-index: 99/*11000*/;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--modal-overlay-background-color);
}
.ConfirmAlertOverlay {
    position: relative;
    left: 50%;
    right: auto;
    top: 50%;
    bottom: auto;
    max-width: 800px;
    min-width: 400px;
    height: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    /*background-color: var(--modal-overlay-background-color);*/
    background-color: var( --modal-background-color);
    border: 1px solid #ced4da;
}

.modal-header {
    background-color: var(--modal-header-color);
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    padding: 0.5rem 0.5rem;
}

.modal-title {
    color: var(--modal-title-color);
    font-weight: 400;
    padding-left: 15px;
}

.modal-text {
    color: var(--modal-text-color);
}

.modal-blue-text {
    color: var(--modal-info-color);
}

.modal-text-small {
    color: var(--modal-info-color);
    font-size: 11px;
}

.modal-text-reset-note {
    color: var(--modal-info-color);
}

.modal-label {
    color: var(--modal-label-color);
}

.modal-select {
    color: var(--modal-select-color);
    width: 100%;
    height: calc(2.25rem +2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #ced4da;
    height: 36px;
    /* border-radius: .25rem; */
    /* -webkit-appearance: none; */
    background-color: transparent;
}

    .modal-select:disabled {
        color: var(--modal-disabled-color) !important;
    }

.modal-file-input {
    color: var(--modal-file-input-color);
}

.inputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
   
}

.inputFile2 {
    color: var(--modal-title-color);
    /* border: 2px solid var(--modal-info-color); */
    background-color: var(--modal-button-color);
    height: 35px;
    /* width: 40px; */
    align-items: center;
    display: flex;
    font-size: 0.875rem !important;
    /* font-weight: 600 !important; */
    margin: -3px;
    padding: 4px 10px 4px 20px;
    /* display: inline-block; */
    width: 11em;
    position: relative;
    margin-left: 15px;
    /* top: -3.75em; */
}

.label-browse-file {
    display: inline-block;
    width: 6em;
    margin-right: .5em;
    padding-top: 2.5em;
}

.file-path {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--modal-button-color);
    width: 143%;
}

.modal-file-path{
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--modal-button-color);
    width: 50%;
}

.modal-file {
    color: var(--modal-title-color);
    background-color: var(--modal-button-color);
    height: 35px;
    align-items: center;
    display: flex;
    font-size: 0.875rem !important;
    margin: -3px;
    width: 11em;
    position: relative;
}

.file-path-wrapper {
    margin-top: 7px;
}

.file-icon {
    font-size: 0.875rem !important;
    margin-right: 5px;
}

.file-title {
    vertical-align: middle;
}

.file-note {
    margin-top: 10px;
    font-size: 0.75rem;
}

.modal-text-input {
    color: var(--modal-text-color);
    width: 100%;
    height: calc(2.25rem +2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #ced4da;
    /* border-radius: .25rem; */
    -webkit-appearance: none;
    background-color: transparent;
}


.modal-body {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 50vh;
}

.modal-body-custom {
    font-size: 13px;
}

#modalContainer .custom-control.custom-checkbox {
    font-weight: 200;
    /*margin-left: 2.5rem;*/
}

.modal-dialog-settings {
    overflow-y: initial !important
}

.modal-button {
    background-color: var(--modal-button-color) !important;
    color: white !important;
    /* background: linear-gradient(to bottom, hsl(50, 100%, 52%) 0%, hsl(45, 100%, 52%) 100%) !important; */
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
}

.nav-item div {
    background-color: transparent;
    /* color: white; */
}

.error {
    color: var(--modal-error-color);
}

.section-title {
    color: var(--modal-section-color);
    font-size: 18px;
}

.info {
    color: var(--modal-info-color);
    font-size: 11px;
    margin-top: 0px;
}

.password {
    padding-left: 22px;
}

.valid {
    color: var(--modal-valid-color);
}

.invalid {
    color: var(--modal-error-color);
}

.ip-input {
    width: 59px;
    text-align: center;
}

.settings-row, .configCMS-row {
    margin-bottom: 15px;
    margin-left: 20px;
}

.modal-row {
    margin-bottom: 15px;
    margin-left: 20px;
}

.subsection-title {
    color: var(--modal-subsection-color);
    font-size: 15px;
}

.modal-date-wrapper {
    padding-left: 0px;
    padding-right: 0px;
}

.modal-date-input {
    background-color: transparent;
    /* color: #aabed0; */
    border: 1px solid #ced4da;
    /* border-radius: .25rem; */
    height: calc(2.25rem +2px);
    padding: .375rem 1.75rem .375rem .75rem;
    width: 100%;
}

.react-datepicker-popper {
    width: max-content;
}

.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    padding: 0;
}

.react-datepicker__time-container {
    width: 85px;
}

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
        width: 100% !important;
    }

.modal-url-input {
    color: var(--modal-text-color);
    margin-top: -6px;
    width: 100%;
    height: calc(2.25rem +2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    /* border-radius: .25rem; */
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    background-color: transparent;
}

.modal-body-custom > .modal-body {
    height: 720px;
    overflow: scroll;
    overflow-x: hidden;
}

.Modal {
    position: relative;
    left: 50%;
    right: auto;
    top: 50%;
    bottom: auto;
    max-width: 800px;
    min-width: 400px;
    height: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    background-color: var( --modal-background-color);
}

/* .modal-close-button {
    color: var(--modal-close-btn-color) !important;
} */

.btn-close {
    color: var(--modal-close-btn-color) !important;
    /*background: linear-gradient(to bottom, hsl(50, 100%, 52%) 0%, hsl(45, 100%, 52%) 100%) !important;*/
    /* box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08) !important; */
    outline: none !important;
    border: none !important;
}

    .btn-close:hover, .btn-close.hover {
        filter: brightness(110%);
        /* box-shadow: 0 7px 14px rgba(50,50,93,.11), 0 3px 6px rgba(0,0,0,.08) !important; */
    }

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem 0rem -1rem auto !important;
}

.nav-tabs .nav-link {
    /* border: 1px solid; */
    color: var(--modal-tab-inactive-color) !important;
    /* border-color: var(--modal-tab-inactive-color) var(--modal-tab-inactive-color) white var(--modal-tab-inactive-color) !important; */
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: 1px solid var(--modal-tab-inactive-color);
    font-size: var(--modal-tabHeader-font-size);
}

    .nav-tabs .nav-link.active {
        color: var(--modal-tab-active-color) !important;
        /* background-color: var(--modal-tab-active-color) !important; */
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        border-color: var(--modal-tab-active-color) var(--modal-tab-active-color) white var(--modal-tab-active-color) !important;
        /* border-color: inherit; */
        /* border-top: 4px solid var(--modal-tab-active-color) !important;   */
        box-shadow: 0 -2px var(--modal-tab-active-color) !important;
        border-bottom: 1px solid var(--modal-tab-active-color) !important;
        /* border-color: var(--modal-tab-active-color) !important;   */
    }

.nav-tabs {
    margin-top: 10px;
    margin-left: 25px;
    /* border-bottom: 1px solid var(--modal-tab-active-color) !important; */
}

input.disabled {
    background-color: white !important;
    color: var(--modal-disabled-color) !important;
}

.form-control {
    font-size: 0.875rem;
    border-radius: 0;
}

.ScreenBeamProduct > .form-control {
    margin-bottom: 10px;
    padding-left: 0.25rem !important;
}

/* .display-screenbeam-product > .col-sm-2 {
    padding-right: 10px;
    float: right;
} */

/* .display-screenbeam-product > .col-sm-3 > .form-control {
    padding-left: 0.25rem !important;
    width: 132% !important;
    font-size: 13px;
    padding: 0 !important;
} */

/* .display-screenbeam-product > .col-sm-5 > .form-control {
    padding-left: 0.25rem !important;
    width: 100% !important;
    font-size: 13px;
    padding: 0 !important;
} */

/* .display-screenbeam-product > .col-sm-2 > .modal-button {
    padding-left: 0.25rem !important;
    width: 105% !important;
    margin-left: 10px !important;
} */

select#versionChoice {
    margin-left: 30px !important;
}

.password-icon {
    float: right;
    margin-left: -25px;
    margin-top: 9px;
    margin-right: 4px;
    position: relative;
    z-index: 2;
}

#fileURLHelp {
    margin-top: 10px !important;
}

.test-url-btn {
    margin-left: 15px;
}

.settings-radio-btns {
    margin-left: 0px;
}

/* URL Input - Label */
.customize-column-width > .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 26.333333%;
}

/* @media (min-width: 576px) */
.modal-date {
    flex: 0 0 33.333333%;
    max-width: 100% !important;
}

/* .react-datepicker-ignore-onclickoutside {
    width: 100% !important;
} */

.setting-section-title >hr {
    margin-top: -1rem;
}

.section-title {
    margin-top: 10px;
}

.a-tag-disabled {
    pointer-events: none;
    cursor: default;
    color: var(--modal-disabled-color)
}

.button-primary-disabled {
    color: var(--modal-disabled-color)
}

.reset-note {
    padding-left: 20px;
}
