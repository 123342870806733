.edit-member-icon, .delete-member-icon {
    cursor: pointer;
}

#user-card>.table-responsive {
    overflow-x: unset;
}

#user-card-wrapper> .card-with-label {
    margin-top: 12px;
    padding-top: 12px;
}

.table-search-input {
    border-radius: 20px !important;
    padding-left: 35px;
}

.search-icon {
    font-size: 19px !important;
    top: 10px;
    color:#a0a5b1;
    left: 10px;
    position: absolute;
}

.delete-member-icon {
    color: #F44336 !important;
    margin-left: 13px;
}

.edit-member-icon {
    color: #FFC107 !important;
}

.user-row {
    margin-top: 0px;
}

.role-selection {
    width: 50%;
    min-width: 128px;
    font-size: 0.82rem;
}

table.dataTable tbody th, table.dataTable tbody td {
    vertical-align: middle;
}