.column-shown {
    color: hsl(0, 0%, 0%);
}

.column-hidden {
    color: hsl(0, 0%, 60%)
}

/*.dropdown-item:hover {
    color: hsl(0, 0%, 0%);
}*/

.group-checkbox-label {
    cursor: pointer;
}

    .group-checkbox-label:hover {
        background-color: rgba(52, 152, 219, 0.6) !important;
    }

.ui-selected {
    background-color: rgba(52, 152, 219, 0.6) !important;
}

.highlight-right-click {
    background-color: rgba(52, 152, 219, 0.3) !important;
}



.status-card-highlight{
    box-shadow: 5px 5px darkblue;
}

/* Table Header */
th {
    font-size: .75rem;
}

/* Table Items */
td {
    font-size: .82rem;
}

.table td, .table th {
    padding: .5rem;
}

.green {
    color: rgb(1, 173, 9);
}

.red {
    color: red;
}

.table--info--light {
    color: hsl(0, 0%, 40%);
}

@media (min-width: 1200px) {
    .min-width-300-xl {
        min-width: 300px;
    }

    .max-width-xl {
        max-width: calc(100% - 310px);
    }
}

div.DTS tbody th, div.DTS tbody td {
    white-space: normal;
}

.dataTables_scrollBody {
   height:200px; 
   overflow:auto;
   position:inherit;
}


/* Responsive Actions button in the Schedule page */
.table-responsive {
    overflow-x: inherit;
}


.dropdown-menu {
    border-style: none;
    /* background-color: #0D1E3F; */
    /* background-color: #8AC440; */
    background-color: moz-linear-gradient(left, rgba(227,227,227,1) 0%, rgba(252,252,252,0.98) 100%);
    border-radius: .5rem;
}




/* Responsive show/Hide columns, select Policy, and Export at Reciever table */
@media screen and (max-width: 768px) {
    .dt-buttons {
        display: inline-block;
    }

    .btn-group > .btn {
        width: 100%;
        display: inline-block;
        margin-bottom: 10px;
        flex: 0 1 auto;
        position: relative;
    }

  
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: inherit;
        max-width: 100%;
    }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: inherit;
        max-width: 100%;
    }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: inherit;
        max-width: 100%;
    }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: inherit;
        max-width: 100%;
    }
}



/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: inherit;
        max-width: 100%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {

    #receivers-table_wrapper .col-md-6:first-child {
        flex: inherit;
        max-width: 100%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 768px) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: 100%;
        max-width: 80%;
    }

    #reciever-table_filter .dataTables_wrapper .dataTables_filter {
        margin-top: 15px;
    }
}

@media (min-width: 1025px) and (max-width: 1999px) {
    #receivers-table_wrapper .col-md-6:first-child {
        flex: 10%;
        max-width: 100%;
    }


    div.dataTables_wrapper div.dataTables_filter {
        text-align: right;
        margin-top: 13px;
    }
}
#actionDetail .justify-content-end {
    margin-right: 10px;
}


/* .site-selected{
    background-color: rgba(52, 152, 219, 0.6) 
}

.site-title:hover{
    background-color: rgba(52, 152, 219, 0.6) 
} */


.site-title:hover{
    background-color: rgba(52, 152, 219, 0.6) ;
    cursor: pointer;
}
.site-selected{
    background-color: rgba(52, 152, 219, 0.6) ;
}



.site-input{
    width: 80%;
}

.site-icon-collapse{
    float: left;
    width:20px;
    height: 20px
}

.Collapsible__trigger{
    float: left;
}

.context-disabled{
    color: gray;    
    /* background-color:black; */
}
.context-disabled:hover{
    background-color: white;
    color: gray;   
}
.context-disabled:hover i{
    color: #2980b9;
}

.context-target{
    background-color: rgb(154, 154, 223) !important
}
.context-target:hover{
    background-color: rgb(154, 154, 223)
}
.context-target.selected{
    background-color: rgb(178, 149, 197) !important
}
.context-target.site-selected{
    background-color: rgb(178, 149, 197)
}
.context-target.site-selected:hover{
    background-color: rgb(178, 149, 197)
}

#AddSiteButton{
    height: 50px;
    position: absolute;
    right: -20px;
    bottom: -20px;
    background-color: white;
}

#AddSiteButton > img{
    height: inherit;
}

#AddSiteButton :hover{
    cursor: pointer;
}

.card-wrapper{
    position: relative;
}

/* Tabs */

.react-tabs__tab{
    background-color: #fff;
    border-color: #A7AABC;
    border-left: 1px solid #A7AABC !important;
    border-right: 1px solid #A7AABC !important;    
    border-top: 1px solid #A7AABC !important;
    border-width: 2px;
    color: #A7AABC;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    /* float: left; */
    padding: 0.625rem 1.25rem;
    position: relative;
    -webkit-transition: 0.1s ease-in-out;
            transition: 0.1s ease-in-out;
    /* margin-right: 5px !important;
    font-size: 16px;
    padding: 10px 36px 10px 36px !important;
    border-radius: 0px !important;
    color: rgb(85, 85, 85) !important; 
    border-width: 1px !important;
    border-color: rgba(139, 139, 139, 0.288) !important; */
}

.react-tabs__tab:last-of-type {
    border-right-style:solid;    
}

.react-tabs__tab--selected{
    border-bottom: 4px solid #00C6C0 !important;  
    border-color: #00C6C0 !important;  
    color:  #00C6C0 !important;  
    position: relative;
    border-radius: 0 !important;
    /* color: #975997; */
    z-index: 1;
    /* background-color: rgb(13,30,63) !important;
    color: white !important; */
}

.react-tabs__tab-list{
    margin-bottom: 0px !important;
    /* border: none !important; */
}

.react-tabs__tab-panel--selected{
    /* border-bottom: 4px solid #00C6C0; */
    border: 1px rgba(139, 139, 139, 0.288) solid !important;
    padding: 10px;
}

.table-button{
    background-color: #00C6C0 !important;
    /* background-color: #8AC440 !important; */
    /* background-color: rgb(152, 211, 64) !important; */
    color: white !important;
    border-radius: 0px !important;
}

/* Status card */

.receivers-status-cards-wrapper{
    padding: 0 20px;
    line-height: 1.8;
}

.receivers-status-card:hover{
    cursor: pointer;
}

.receivers-status-card{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    /* background-color: #fff; */
    background-clip: border-box;
    border: 1px solid var(--total-color);
    color: white !important;
    font-weight: 500;
    text-align: center;
    padding: 0.25rem 1.25rem;
    margin-bottom: 0;
    background-color: var(--total-color);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    /* border-radius: .25rem; */
}


.receivers-status-cards-wrapper .col-md-2 {
    padding-right: 0;
    padding-left: 0;
}

.receivers-status-card.gray {
    background-color: var(--idle-color);
    border: 1px solid var(--idle-color);   
}

.receivers-status-card.green{
    background-color: var(--busy-color);    
    border: 1px solid var(--busy-color);
}

.receivers-status-card.red{
    background-color: var(--offline-color);    
    border: 1px solid var(--offline-color);
}

/* .receivers-status-card-count{
    font-size: 32px;
}

.receivers-status-card-name{
    margin-left: 5px;
    font-size: 14px;
}

.receivers-card-header {
    color: white !important;
    font-weight: 500;
    text-align: center;
    padding: 0.25rem 1.25rem;
    margin-bottom: 0;
    background-color: var(--total-color);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.receivers-card-header.green {
    background-color: var(--busy-color);
}

.receivers-card-header.red {
    background-color: var(--offline-color);
}

.receivers-card-header.gray {
    background-color: var(--idle-color);
} */

/*.receivers-card-body {
    flex: 1 1 auto;
    padding: 0;
}*/

/* .receivers-card-text {
    color: var(--total-color);
    text-align: center;
    font-size: 20px;
    margin: 4px 4px;
    font-weight: 500;
}

.receivers-card-text.green {
    color: var(--busy-color);
}

.receivers-card-text.red{
    color: var(--offline-color);
}

.receivers-card-text.gray{
    color: var(--idle-color);
} */

.table-search-input::-ms-clear{
    display: none;
}

.table-search-input.form-control {
    font-size: 0.875rem !important;
    border-radius: 0;
}

.input-user-box {
    /* padding-left: 10px; */
}

.output-user-box {
    margin-top: 10px;
}

.name-search {
    padding-left: 10px;
}

.circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: #00C6C0;
    position: relative;
  }
  .circle::after {
    content: " ";
    position: absolute;
    display: block;
    background-color: #fff;
    height: 7px;
    margin-top: -4px;
    top: 50%;
    left: 14px;
    right: 14px;
    z-index: 9;
  }
  .circle::before {
    content: " ";
    position: absolute;
    display: block;
    background-color: #fff;
    width: 7px;
    margin-left: -4px;
    left: 50%;
    top: 14px;
    bottom: 14px;
    z-index: 9;
  }

  .add-site {
      cursor: pointer;
  }

  .dropdown-item {
    cursor: pointer;
    
  }