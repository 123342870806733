#receiver-log-page>.col-md-12 {
    margin: 30px 30px;
    max-width: 96%;

}

.receiver-log-btns {
    margin-bottom: 20px;
    top: 0;
    right: 0;
    padding-left: 20px;
}

/* Fixed issue that the datepicker is under the table rows */
.receiver-log-btns .react-datepicker-popper {
    z-index: 3;
}

.receiver-log-btns .react-datepicker-wrapper {
    width: 34%;
}

.receiver-log-datepicker {
    margin-top: 7px;
}

.react-datepicker__input-container>.mr-1 {
    padding-left: 10px !important;
    width: 150px;
}

#export-search-btn {
    margin-right: 70px;
    right:0px;
    position: fixed;
}

/* #search-btn {
    margin-left: -40px;
} */

.table-wrapper {
    background: #fff;
    padding: 8px 0px;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}

.table-title {
    background:#00C6C0; 
    padding-bottom: 15px;
    color: #fff;
    padding: 8px 30px;
    margin: -20px 0px 10px;
    border-radius: 3px 3px 0 0;
}

.table-title h2 {
    margin: 5px 0 0;
    font-size: 24px;
}

.table-btns {
    margin-top: 10px !important;
}

#receiver-log-card .card-wrapper {
    margin-top: -10px;
}

.rx-log-date {
    margin-top: 7px;
}

/* .react-datepicker-ignore-onclickoutside {
    width: 150px;
} */
